import Api from 'libs/api';

class ComposeApi extends Api {
  constructor(resource = '') {
    super(resource);
  }

  getPlaceHolder(id, params = {}) {
    return this.buildQuery(
      'get',
      params,
      `companies/${id}/fetch_contact_fields`,
    );
  }

  saveTemplate(data) {
    return this.buildQuery(
      'post',
      {
        data: {
          ...data,
        },
      },
      'mail_templates',
    );
  }

  updateTemplate(data) {
    return this.buildQuery(
      'patch',
      {
        data,
      },
      `mail_templates/${data.id}`,
    );
  }

  archiveTemplate(id, data) {
    return this.buildQuery(
      'patch',
      {
        data,
      },
      `mail_templates/${id}`,
    );
  }

  getTemplates(params) {
    return this.buildQuery(
      'get',
      params,
      `mail_templates?per_page=${params.per_page ? params.per_page : 5}&`,
    );
  }
  deleteTemplate(id, params) {
    return this.buildQuery('post', params, `mail_templates/${id}/soft_delete`);
  }

  copyTemplate(params) {
    return this.buildQuery('post', params, 'mail_templates/copy_template');
  }

  createLink = (params) => {
    return this.buildQuery('post', params, 'mail_links');
  };
}

export default ComposeApi;
